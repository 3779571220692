export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-east-1",
      BUCKET: "jws-notes-app-uploads"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://oebw0gy7ff.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_LRPN5ujAx",
      APP_CLIENT_ID: "54ba0aqq60jb54jmijj9uebbb9",
      IDENTITY_POOL_ID: "us-east-1:a47948db-53fd-4c54-9631-910f8b40ad45"
    }
  };